<template>
    <div class="tab-text">
        <div class="vx-row mb-12">
            <div class="core vx-col md:w-1/1 w-full mb-base">
                <data-table :baseUrl="baseUrl" :status=9 :isFilter="isfilter" @isFilter="setFilter" />
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from "../DataTable";

export default {
    components: {
        DataTable
    },
    data() {
        return {
            title: "Work Order",
            baseUrl: "api/wms/v1/work-order",
            id: null,
        }
    }
}
</script>
